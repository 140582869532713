@mixin redesign-button-variant(
    $name,
    $base-color,
    $light-color-variant,
    $dark-color-variant,
    $text-color: var(--light-text),
    $disabled-text-color: var(--light-text)
) {
    .btn-#{$name} {
        color: $text-color;
        border-color: $base-color;
        background-color: $base-color;

        &.disabled,
        &:disabled {
            opacity: 1;
            background-color: $light-color-variant;
            border-color: $light-color-variant;
            color: $disabled-text-color;
        }

        &:not(:disabled):not(.disabled) {
            &:hover:not(.focus):not(:focus) {
                color: $text-color;
                background-color: $dark-color-variant;
            }

            &:focus,
            &.focus,
            &:active,
            &.active {
                color: $text-color;
                background-color: $base-color;
                border-color: var(--body-bg);

                @at-root #{selector-append('.theme-light', &)} {
                    box-shadow: 0 0 0 2px $light-color-variant;
                }
                @at-root #{selector-append('.theme-dark', &)} {
                    box-shadow: 0 0 0 2px $dark-color-variant;
                }
            }
        }
    }

    .show > .btn-#{$name}.dropdown-toggle {
        color: $text-color;
        background-color: $base-color;
        border-color: var(--body-bg);

        @at-root #{selector-append('.theme-light', &)} {
            box-shadow: 0 0 0 2px $light-color-variant;
        }
        @at-root #{selector-append('.theme-dark', &)} {
            box-shadow: 0 0 0 2px $dark-color-variant;
        }
    }

    .btn-outline-#{$name} {
        color: var(--body-color);
        border-color: $base-color;
        background-color: transparent;

        &.disabled,
        &:disabled {
            opacity: 1;
            color: var(--text-muted);
            background-color: var(--color-bg-1);
            border-color: $light-color-variant;
        }

        &:not(:disabled):not(.disabled) {
            // Set inlined icons to match outline border
            svg {
                fill: $base-color;
            }

            &:hover:not(.focus):not(:focus) {
                background-color: var(--body-bg);
                @at-root #{selector-append('.theme-light', &)} {
                    color: $dark-color-variant;
                    border-color: $dark-color-variant;

                    svg {
                        fill: $dark-color-variant;
                    }
                }
                @at-root #{selector-append('.theme-dark', &)} {
                    color: $base-color;
                    border-color: $base-color;
                }
            }

            &:focus,
            &.focus,
            &:active,
            &.active {
                color: var(--body-color);
                border-color: var(--body-bg);
                background-color: var(--body-bg);
                @at-root #{selector-append('.theme-light', &)} {
                    box-shadow: 0 0 0 2px $light-color-variant;
                }
                @at-root #{selector-append('.theme-dark', &)} {
                    box-shadow: 0 0 0 2px $dark-color-variant;
                }
            }
        }
    }

    .show > .btn-outline-#{$name}.dropdown-toggle {
        color: var(--body-color);
        border-color: var(--body-bg);
        background-color: var(--body-bg);
        @at-root #{selector-append('.theme-light', &)} {
            box-shadow: 0 0 0 2px $light-color-variant;
        }
        @at-root #{selector-append('.theme-dark', &)} {
            box-shadow: 0 0 0 2px $dark-color-variant;
        }
    }
}

.theme-redesign {
    @include redesign-button-variant(
        $name: 'primary',
        $base-color: var(--primary),
        $light-color-variant: var(--primary-2),
        $dark-color-variant: var(--primary-3)
    );

    @include redesign-button-variant(
        $name: 'secondary',
        $base-color: var(--secondary),
        $light-color-variant: var(--secondary-2),
        $dark-color-variant: var(--secondary-3),
        // Use darker text colors for contrast
        $text-color: var(--body-color),
        $disabled-text-color: var(--text-muted)
    );

    @include redesign-button-variant(
        $name: 'success',
        $base-color: var(--success),
        $light-color-variant: var(--success-2),
        $dark-color-variant: var(--success-3)
    );

    @include redesign-button-variant(
        $name: 'danger',
        $base-color: var(--danger),
        $light-color-variant: var(--danger-2),
        $dark-color-variant: var(--danger-3)
    );

    @include redesign-button-variant(
        $name: 'warning',
        $base-color: var(--warning),
        $light-color-variant: var(--warning-2),
        $dark-color-variant: var(--warning-3),
        // Use darker text colors for contrast
        $text-color: var(--dark-text),
        $disabled-text-color: var(--text-muted)
    );

    @include redesign-button-variant(
        $name: 'info',
        $base-color: var(--info),
        $light-color-variant: var(--info-2),
        $dark-color-variant: var(--info-3),
        // Use darker text colors for contrast
        $text-color: var(--dark-text),
        $disabled-text-color: var(--text-muted)
    );

    @include redesign-button-variant(
        $name: 'merged',
        $base-color: var(--merged),
        $light-color-variant: var(--merged-2),
        $dark-color-variant: var(--merged-3)
    );

    .btn,
    // Required because `.btn.btn-sm { @extend small }` includes `font-weight: inherit;` that we don't need.
    .btn.btn-sm {
        // Use placeholder-selector to extend only styles defined for `.theme-redesign label`.
        @extend %label-base;
    }

    .btn.btn-sm {
        @extend %label-base-small;
    }

    // Label / Uppercase / Base
    .btn.text-uppercase {
        @extend %label-uppercase;

        // Label / Uppercase / Small
        &.btn-sm {
            font-size: 0.6875rem;
            @extend %label-uppercase;
        }
    }
}

.theme-dark.theme-redesign,
.theme-light.theme-redesign {
    // btn-secondary needs specific styles to ensure good contrast
    .btn-secondary,
    .btn-outline-secondary {
        &.disabled,
        &:disabled {
            border-color: var(--input-disabled-bg);
        }

        &:not(:disabled):not(.disabled) {
            &:hover:not(.focus):not(:focus) {
                color: var(--body-color);

                svg {
                    fill: var(--body-color);
                }
            }

            &:focus,
            &.focus,
            &:active,
            &.active {
                box-shadow: 0 0 0 2px var(--primary-2);
            }

            svg {
                fill: var(--body-color);
            }
        }
    }
}
