.codeintel-uploads {
    &__grid {
        display: grid;
        grid-template-columns: [info] minmax(auto, 1fr) [state] min-content [caret] min-content [end];
        row-gap: 1rem;
        column-gap: 1rem;
        align-items: center;
        @media (--sm-breakpoint-down) {
            row-gap: 0.5rem;
            column-gap: 0.5rem;
        }
    }
}
