.pie-chart {
    // Arcs
    &__arc {
        stroke: var(--body-bg);
    }

    &__arc--with-link {
        cursor: pointer;
    }

    &__arc-path {
        stroke-width: 1;
        stroke: var(--body-bg);
    }

    &__label {
        background-color: var(--body-bg);

        // We don't have access to this element to add our own class
        // that's why we relay on tag name here.
        rect {
            fill: var(--body-bg);
            stroke-width: 1;
            stroke: var(--border-color);
        }
    }

    &__link:hover {
        text-decoration: none;
    }

    &__link:focus &__arc-path {
        stroke-width: 3;
        stroke: var(--focus-outline-color);
    }

    &__label-title {
        stroke: var(--body-color);
        font-weight: 200 !important;
        background-color: var(--body-bg);
        text-decoration: none;
    }

    &__label-sub-title {
        stroke: var(--text-muted);
        text-decoration: none;
    }

    &__label-line,
    &__label-circle {
        stroke: var(--body-color);
        fill: var(--body-color);
    }
}
