@import './LineDecorator.scss';

.blob {
    position: relative;
    overflow: auto;
    padding-top: 0.5rem;
    tab-size: 4;
    display: flex;

    &__code {
        flex: 1;

        table {
            border-collapse: collapse;
        }

        td.line {
            &::before {
                // draw line number with css so it cannot be copied to clipboard
                content: attr(data-line);
            }

            text-align: right;
            padding-left: 0.5rem;
            min-width: 2rem;
            user-select: none;
            vertical-align: top;
            color: var(--line-number-color);
        }

        tr {
            transition: background 200ms ease-out;
            &.selected {
                background: var(--color-bg-4);
            }
        }

        td.line,
        td.code {
            padding: 0;
        }

        td.code {
            width: 100%;
            padding-left: 1rem;
            white-space: pre;

            div {
                display: inline-block;
            }

            .line-decoration-attachment-portal {
                span::before {
                    content: attr(data-contents);
                }
            }
        }

        &--wrapped {
            td.code {
                white-space: pre-wrap;
            }
        }
    }
}
