.extensions-list {
    &__cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(17.5rem, 1fr));
        grid-auto-rows: minmax(4rem, auto);
        gap: $spacer * 0.75;
    }

    &__category {
        margin-top: 2rem;
        font-size: 1.5rem !important;
    }
}
