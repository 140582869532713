.theme-redesign {
    .user-nav-item {
        &__avatar {
            height: 1.5rem;
            width: 1.5rem;
        }
        &__dropdown-menu {
            > .dropdown-item {
                &:hover {
                    background-color: var(--link-color);
                    color: var(--light-text);
                }
            }
            > .dropdown-header {
                font-size: 0.75rem;
            }
            > .dropdown-divider {
                border-top: 1px solid var(--border-color-2);
            }
        }
    }
}

.user-nav-item {
    &__dropdown-menu {
        min-width: 12rem;
    }

    &__tooltip {
        opacity: 0;
        animation: tooltip-fade-in-out 5s ease-out 100ms;
    }

    &__avatar-background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        opacity: 0;
        animation: background-fade-in-out 5s ease-out 100ms;

        background: rgba(56, 117, 127, 0.3);
        filter: blur(1px);
    }
}

@keyframes tooltip-fade-in-out {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    84% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes background-fade-in-out {
    0% {
        opacity: 0;
        transform: scale(1, 1);
    }

    11% {
        opacity: 0;
        transform: scale(1, 1);
    }

    27% {
        opacity: 1;
        transform: scale(1.5, 1.5);
    }

    84% {
        opacity: 1;
        transform: scale(1.5, 1.5);
    }

    100% {
        opacity: 0;
        transform: scale(1, 1);
    }
}
