.add-external-services-page {
    &__button {
        display: block;
    }
}

.add-external-services-page__card {
    display: block;
    margin-bottom: 1rem;
}

.external-services-form {
    &__quick-configure-button {
        margin: 0.25rem 0.25rem 0.25rem 0;
    }
    &__quick-configure-label {
        font-weight: bold;
    }
}
