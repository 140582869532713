.registry-extension-overview-page {
    &__readme {
        flex: 1;
        min-width: 20rem;
    }
    &__sidebar {
        width: 16rem;
    }
    &__tag {
        max-width: 24ch;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__icon {
        display: block;
        width: 4.5rem;
        height: 4.5rem;
        object-fit: contain;
    }
}
