.theme-redesign {
    h1 {
        font-size: 1.625rem; // 26px
        font-weight: 600;
        letter-spacing: (0.5/26) + em;
    }

    h2 {
        font-size: 1.25rem; // 20px
        font-weight: 600;
        letter-spacing: (0.25/20) + em;
    }

    h3 {
        font-size: 1rem; // 16px
        font-weight: 600;
        letter-spacing: (0.25/16) + em;
    }

    h4 {
        font-size: 0.875rem; // 14px
        font-weight: 500;
        letter-spacing: (0.25/14) + em;
    }

    h5 {
        font-size: 0.625rem; // 10px
        font-weight: 600;
        letter-spacing: (0.5/10) + em;
        text-transform: uppercase;
    }

    h6 {
        font-size: 0.625rem;
        font-weight: 400;
    }

    // Body / Base / Medium
    .font-weight-medium {
        font-weight: 500;
    }

    // Body / Base / Strong
    strong {
        font-weight: 600;
    }

    // Body / Small / Regular
    small {
        // Use string interpolation and calc() to avoid using Sass own max() function.
        // See: https://github.com/sass/node-sass/issues/2815#issuecomment-575926329
        font-size: calc(min(0.75rem, #{(11/12)}em));
        // Bootstrap applies `font-weight: 400;` to `small` element,
        // but we want to control `font-weight` only with `strong` or `.font-weight-medium`.
        font-weight: inherit;
    }

    // Input / Small
    .form-control-sm,
    %form-control-sm,
    .custom-select-sm,
    %custom-select-sm {
        font-size: (11/14) + em;
    }

    // Label / Base
    label,
    %label-base {
        font-weight: 500;
        letter-spacing: -(0.1/14) + em;
    }

    label small,
    %label-base-small {
        letter-spacing: -(0.25/12) + em;
    }

    // Label / Uppercase
    label.text-uppercase,
    %label-uppercase {
        font-size: 0.75rem;
        letter-spacing: (0.25/14) + em;
    }

    label.text-uppercase small,
    %label-uppercase-small {
        font-size: 0.75rem;
        letter-spacing: -(0.25/12) + em;
    }
}
