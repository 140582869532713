@import './RepoSettingsIndexPage';
@import './RepoSettingsMirrorPage';
@import './RepoSettingsOptionsPage';

.repo-settings-area {
    overflow: auto;
    &__header {
        &-item {
            display: flex;
            align-items: center;
            padding-left: 0.25rem;
            font-weight: bold;
        }
        &-btn {
            display: inline-flex;
        }
    }
}
