.saved-search-modal-form {
    padding: 1rem;
    background: var(--color-bg-1);
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    width: 20rem;
    max-width: 100vw;

    &__button {
        width: 100%;
    }

    &__select {
        margin-bottom: 0.5rem;
    }
}
