@import 'bootstrap/scss/type';

h1 {
    font-size: 1.75rem;
    font-weight: 500;
    margin: 0 0 1rem;
}

h2 {
    font-size: 1.5rem;
    font-weight: 500;
}

h3 {
    font-size: 1rem;
    font-weight: 600;
}

h4 {
    font-size: 0.875rem;
    font-weight: 600;
}

h5 {
    font-size: 0.875rem;
    letter-spacing: 0.05em;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
}

h6 {
    font-size: 0.75rem;
    font-weight: 600;
}

small {
    font-size: 0.75rem;
}

sup,
sub {
    font-size: 0.5em;
}
sup {
    top: -1em;
}

legend {
    font-size: 1rem;
}

.font-weight-medium {
    font-weight: 600;
}

// Make sure text color utilities are theme-aware
.theme-light {
    @each $color, $value in $theme-colors-light {
        @include text-emphasis-variant('.text-#{$color}', $value, true);
    }
}

.theme-light.theme-redesign,
.theme-dark.theme-redesign {
    @each $color, $value in $theme-colors-redesign {
        @include text-emphasis-variant('.text-#{$color}', $value, true);
    }
}

.list-dashed {
    list-style: none;
    position: relative;
    > li::before {
        content: '–';
        position: absolute;
        width: 2.5rem;
        left: 0;
        // stylelint-disable-next-line declaration-property-unit-whitelist
        padding-right: 0.5em;
        text-align: right;
    }
}

@import './typography-redesign';
