@import './FormTriggerArea';
@import './FormActionArea.scss';

.code-monitor-form {
    &__card {
        border-radius: 4px;
        // stylelint-disable-next-line declaration-property-unit-whitelist
        margin: calc(1rem - 1px) 0;

        &-link {
            &:hover {
                text-decoration: none;
                color: var(--link-color);
            }
        }

        &--button {
            display: flex;
            width: 100%;
            text-align: left;
            padding: 1rem;
            margin: 1rem 0;
            border-radius: 4px;
            border: 1px solid var(--border-color);

            &:hover:not(:disabled) {
                // stylelint-disable-next-line declaration-property-unit-whitelist
                padding: calc(1rem - 1px);
                border: 2px solid var(--color-border-active);
            }
        }
    }

    &__horizontal-rule {
        margin: 2rem 0;
        border-top: 1px solid var(--border-color-2);
    }

    &__owner-dropdown {
        min-width: 50%;
    }

    &__actions,
    &__triggers {
        &--disabled {
            color: $gray-17 !important;
            opacity: 0.5;

            // Avoid applying opacity twice
            .code-monitor-form__card--button:disabled {
                opacity: unset;
            }
        }
    }
}
