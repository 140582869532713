$toggle-width: 2rem;
$box-shadow-spacing: 0 0 0 1px var(--body-bg);

.toggle {
    --toggle-bar-bg: var(--text-muted);
    --toggle-bar-bg-on: var(--primary);
    --toggle-knob-bg: var(--text-muted);
    --toggle-knob-bg-on: var(--primary);
    --toggle-bar-opacity: 0.2;
    --toggle-bar-focus-opacity: 0.5;
    --toggle-knob-disabled-opacity: 0.2;
    --toggle-bar-focus-box-shadow: #{$box-shadow-spacing}, 0 0 0 0.1875rem var(--primary);

    .theme-redesign & {
        --toggle-bar-bg: var(--icon-color);
        --toggle-bar-bg-on: var(--primary);
        --toggle-knob-bg: var(--body-bg);
        --toggle-knob-bg-on: var(--body-bg);
        --toggle-bar-opacity: 1;
        --toggle-bar-focus-opacity: 1;
        --toggle-knob-disabled-opacity: 1;
        --toggle-bar-focus-box-shadow: #{$box-shadow-spacing}, 0 0 0 0.1875rem var(--primary-2);
    }

    background: none;
    border: none;
    display: inline-block;
    outline: none !important;
    padding: 0;
    position: relative;
    width: $toggle-width;

    &:focus-visible {
        // Move focus style to the rounded bar
        box-shadow: none;
    }

    &:focus-visible &__bar {
        box-shadow: var(--toggle-bar-focus-box-shadow);
    }

    &__bar {
        border-radius: 1rem;
        top: 2px;
        left: 0;
        height: 1rem;
        width: 100%;
        position: absolute;

        opacity: var(--toggle-bar-opacity);
        background-color: var(--toggle-bar-bg);

        transition: all 0.3s;
        transition-property: opacity;

        &--on {
            background-color: var(--toggle-bar-bg-on);
        }
    }

    &__knob {
        background-color: var(--toggle-knob-bg);

        border-radius: 0.375rem;
        display: block;

        height: 0.75rem;
        width: 0.75rem;
        margin-top: 0.25rem;
        left: 0.125rem;

        position: relative;

        &--on {
            background-color: var(--toggle-knob-bg-on);
            transform: translate3d(1rem, 0, 0);
        }
    }

    &:hover:enabled &__bar {
        opacity: var(--toggle-bar-focus-opacity);
    }

    .theme-redesign &:disabled {
        --toggle-knob-bg: var(--icon-color);
        --toggle-knob-bg-on: var(--icon-color);
        --toggle-bar-bg: var(--input-disabled-bg);
        --toggle-bar-bg-on: var(--input-disabled-bg);
    }

    &:disabled &__knob {
        opacity: var(--toggle-knob-disabled-opacity);
    }
}
