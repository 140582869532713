.query-builder-input-row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    &__label {
        flex: 0 0 8rem;
        display: flex;
        align-items: center;
        margin: 0;
        font-weight: 600;
    }

    &__input {
        flex: 1 1 auto;
    }

    &__description {
        flex: 1 1 auto;
        padding-top: 0.25rem;
        padding-bottom: 0.5rem;
        padding-right: 1rem;
        margin-left: 8rem;
    }
}
