.saved-search-form {
    &__header {
        margin-bottom: 1rem;
    }
    &__input {
        margin: 1rem 0;
    }
    &__checkbox {
        margin-right: 0.25rem;
    }
    &__label {
        font-weight: bold;
    }

    &__submit-button {
        margin-bottom: 1rem;
    }
}
