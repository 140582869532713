@import './DiffStat';
@import './FileDiffHunks';

.file-diff-node {
    margin-bottom: 1rem;

    &__header {
        display: flex;
        align-items: center;

        overflow-x: hidden;

        // decrease from .card-header
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;

        &-path-stat {
            display: flex;
            align-items: center;
            overflow: hidden;
            flex-grow: 1;
        }
        &-path {
            white-space: pre;
            text-overflow: ellipsis;
            overflow: hidden;
            font-family: $code-font-family;
            color: var(--body-color);
            font-size: $code-font-size;
        }
        &-stat {
            margin-right: 0.5rem;
        }

        &-actions {
            // unset from .card-header
            font-weight: initial;
            opacity: initial;

            display: flex;
            align-items: center;
        }
    }

    &__hunks {
        flex: 1 1 auto;
    }
}
