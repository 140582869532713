@import './Blob';
@import './RenderedFile';

.blob-page {
    display: flex;
    flex-direction: column;

    &__placeholder {
        flex: 1 1 50%;
    }

    &__blob {
        flex: 1;
        position: relative;
    }

    &__aborted-alert {
        margin-bottom: 0;
        border: none;
    }
}
