.search-results-info-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    color: #93a9c8;
    padding: 0.25rem 0.5rem;
    // TODO(tj): might need to remove this for action items toggle

    .alert {
        margin-bottom: 0.5rem;
    }

    .mdi-icon {
        margin-right: 0.125rem;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 0.5rem;
        height: 100%;
        flex: 1 0 auto;
        text-align: right;

        .theme-redesign & {
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;
        }
    }

    &__notice {
        display: flex;
        align-items: center;
        margin-right: 1rem;
    }

    &__divider {
        height: 1rem;
        border-right: 1px solid var(--border-color);
        margin: 0 0.5rem;

        .theme-redesign & {
            margin-left: 0;

            &:first-child {
                display: none;
            }
        }
    }

    &__expander {
        display: none;

        .theme-redesign & {
            display: block;
            flex-grow: 1;
            margin-right: 0; // Reset gap
        }
    }

    .theme-redesign & {
        padding-left: 0;
    }
}
