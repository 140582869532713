.survey-form {
    width: 30rem;
    max-width: calc(100vw - 2rem);

    &__error {
        color: $danger;
    }

    &__loader {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__label {
        width: 100%;
        text-align: left;
    }

    &__scores {
        margin-bottom: 2rem;
    }
}

.survey-page {
    width: 100%;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
