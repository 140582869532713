.org-avatar {
    border-radius: 100%;
    background: #a2b0cd;
    color: #39496a;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &--md {
        // stylelint-disable-next-line declaration-property-unit-whitelist
        font-size: 0.625rem;
        height: 1.5rem;
        width: 1.5rem;
    }

    &--lg {
        // stylelint-disable-next-line declaration-property-unit-whitelist
        font-size: 1.25rem;
        height: 3rem;
        width: 3rem;
    }
}
