$color-addition: $green;
$color-deletion: $red;

.diff-boundary {
    &__num {
        background-color: var(--color-bg-2);
    }
    &__num,
    &__content {
        padding-top: 0.325rem;
        padding-bottom: 0.325rem;
    }
    &__content {
        opacity: 0.7;
    }
}

.diff-hunk {
    &__num {
        background-color: var(--color-bg-2);
        min-width: 2.5rem;
        font-size: 0.75rem;
        line-height: (20/12);
        white-space: nowrap;
        text-align: right;
        user-select: none;
        vertical-align: top !important;
        font-family: $code-font-family;
        padding: 0 0.5rem;
        &--line {
            color: var(--text-muted);
            display: block;
            &:hover {
                color: var(--body-color);
                text-decoration: none;
            }
        }
    }
    &__content {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-family: $code-font-family;
        white-space: pre-wrap;
        font-size: 0.75rem;
        &::before {
            padding-right: 0.5rem;
            content: attr(data-diff-marker);
        }
        &--empty {
            background-color: var(--color-bg-2);
            // This cell is always empty opacity will not affect any content
            opacity: 0.5;
        }
    }

    &--split {
        table-layout: fixed;
        &__line {
            &--code {
                word-wrap: break-word;
                white-space: pre-wrap;
                div {
                    display: inline;
                }
            }
            &--active {
                background-color: rgba($oc-yellow-5, 0.17) !important;
                &-num {
                    background-color: rgba($oc-yellow-5, 0.25);
                }
            }
            &--addition {
                background-color: rgba($color-addition, 0.17);
                &-num {
                    background-color: rgba($color-addition, 0.25);
                }
            }
            &--deletion {
                background-color: rgba($color-deletion, 0.17);
                &-num {
                    background-color: rgba($color-deletion, 0.25);
                }
            }
        }
    }

    &__line--active &__num {
        background-color: rgba($oc-yellow-5, 0.35) !important;
    }
    &__line--active &__content {
        background-color: rgba($oc-yellow-5, 0.25) !important;
    }
    &__line--addition &__num {
        background-color: rgba($color-addition, 0.25);
    }
    &__line--addition &__content {
        background-color: rgba($color-addition, 0.17);
    }
    &__line--deletion &__num {
        background-color: rgba($color-deletion, 0.25);
    }
    &__line--deletion &__content {
        background-color: rgba($color-deletion, 0.17);
    }
    &__line--both &__num,
    &__num--both {
        background-color: var(--color-bg-2);
    }
}

.file-diff-hunks {
    &__container {
        // Must apply overflow-x to the container, not to the outer .file-diff-hunks, or else per the CSS spec
        // overflow-y:auto would also be applied, and the code intel hover would be clipped.
        overflow-x: auto;
    }
    position: relative;

    border-collapse: separate;

    &__table {
        width: 100%;
    }
}
