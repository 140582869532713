.search-contexts-list-tab {
    &__auto-defined-search-contexts {
        display: grid;
        gap: 1rem;

        &--repeat-2 {
            grid-template-columns: repeat(2, 1fr);
        }

        &--repeat-3 {
            grid-template-columns: repeat(3, 1fr);
        }

        .badge {
            cursor: default;
        }
    }

    &__filter-input {
        max-width: 30%;
    }
}
