.file-locations {
    overflow-y: auto;
    &__not-found {
        opacity: 0.8;
    }

    .result-container {
        margin-bottom: 0;

        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        border-radius: 0;
    }
}
