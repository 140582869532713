@import './RepoRevisionSidebarSymbols';

.repo-revision-container {
    &__tabpanels {
        height: calc(100% - 2rem);
    }

    &__close-icon {
        color: var(--link-color);
    }

    &__toggle {
        color: var(--link-color);
        background-color: var(--color-bg-2);
        isolation: isolate;
        width: 2.5rem;
        height: 2.5rem;
        margin-right: -1.25rem;
        display: flex;
        justify-content: center;
        z-index: 1;
    }
}
