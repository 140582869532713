.save-toolbar {
    margin-top: 0.25rem;

    &__actions {
        display: flex;
    }

    &__btn {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: none;
        border-left: none;
        &-first {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        &-last {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    &__item {
        display: flex;
        align-items: center;
    }

    &__message {
        margin-left: 0.5rem;
    }

    &__error {
        color: $danger;
        margin: 0.5rem 0;

        &-icon {
            vertical-align: middle;
            margin-right: 0.25rem;
        }
    }
}
