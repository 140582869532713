.action-container {
    margin: 1.5rem 0;
    padding: 1rem;

    border: 1px solid var(--border-color);
    border-radius: 0.125rem;

    &__row {
        display: flex;
        align-items: top;
        justify-content: space-between;
    }

    &__title {
        margin-bottom: 0;
    }

    &__btn {
        display: flex;
        align-items: center;
        flex: 0 0 auto;

        &-container {
            margin-left: 1.5rem;
            flex: 0 0 auto;
            align-self: flex-start;

            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-subtitle {
            color: $danger;
        }
    }

    &__flash {
        opacity: 0;
        transition: opacity 500ms ease-out;

        &--visible {
            opacity: 1;
        }
    }

    &__alert {
        width: 100%;
        flex: 1;
        margin-top: 1rem;
    }
}
