.timeline {
    &__executor-task-separator {
        height: 2.25rem;
        border-left: 4px solid var(--border-color);
        margin-left: 1.5rem;
        margin-top: -1rem;
        margin-bottom: -1rem;
    }

    &__executor-task-icon {
        border-radius: 50%;
        width: 2.25rem;
        height: 2.25rem;
        color: #ffffff;
        padding-top: 0.375rem;

        svg {
            display: block;
            margin: 0 auto;
        }
    }

    &__executor-task-details {
        border-left: 4px solid var(--border-color);
        margin-left: 1.5rem;
        margin-top: -1rem;
        margin-bottom: -1rem;
        padding-left: 2rem;
        padding-top: 1rem;
        padding-bottom: 2rem;
    }
}
