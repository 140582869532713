.query-builder {
    border-radius: 0.25rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    h3,
    label {
        letter-spacing: 0.015rem;
    }

    &__header,
    &__row {
        display: flex;
        width: 100%;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;

        &-example {
            flex: 0 0 13rem;
        }
    }

    &__section {
        border: 1px solid $input-border-color;
        border-radius: 0.125rem;
        padding: 0.5rem;
    }

    &__header {
        margin-top: 0.75rem;
        margin-bottom: 0.25rem;

        &-input {
            flex: 2 0 16rem;
        }

        &-example {
            font-weight: bold;

            @media (--md-breakpoint-up) {
                display: none;
            }
        }
    }

    &__row {
        flex-wrap: wrap;

        &-label {
            flex: 0 0 8rem;
            display: flex;
            align-items: center;
            margin: 0;
            font-weight: 600;
        }

        &-input {
            flex: 1 1 auto;
        }

        &-example {
            display: flex;
            align-items: center;

            @media (--md-breakpoint-up) {
                width: 100%;
            }
        }

        &-description {
            flex: 1 1 auto;
            padding-top: 0.25rem;
            padding-bottom: 0.5rem;
            padding-right: 1rem;
            margin-left: 8rem;
        }
    }

    &__docs-link {
        padding-top: 1.5rem;
    }
}
