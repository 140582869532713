.site-admin-migrations {
    &__grid {
        display: grid;
        grid-template-columns: [info] minmax(auto, 1fr) [progress] min-content [end];
        row-gap: 1rem;
        column-gap: 1rem;
        align-items: center;
        @media (--sm-breakpoint-down) {
            row-gap: 0.5rem;
            column-gap: 0.5rem;
        }
    }
}

.site-admin-migration-node {
    &__separator {
        // Make it full width in the current row.
        grid-column: 1 / -1;
        border-top: 1px solid var(--border-color-2);
        @media (--xs-breakpoint-down) {
            margin-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    &__progress,
    &__information {
        @media (--xs-breakpoint-down) {
            grid-column: 1 / -1;
        }
    }

    &-errors {
        // Make it full width in the current row.
        grid-column: 1 / -1;

        &__grid {
            display: grid;
            grid-template-columns: [date] max-content [error] minmax(auto, 1fr) [end];
            align-items: center;

            &-code {
                border-left: 1px solid var(--border-color-2);
            }
        }
    }
}
