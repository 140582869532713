.symbol-icon {
    $symbol-kinds: (
        'array': $oc-red-list,
        'boolean': $oc-red-list,
        'class': $oc-orange-list,
        'constant': $oc-indigo-list,
        'constructor': $oc-violet-list,
        'enum': $oc-blue-list,
        'enummember': $oc-blue-list,
        'event': $oc-red-list,
        'field': $oc-blue-list,
        'file': $oc-gray-list,
        'function': $oc-violet-list,
        'interface': $oc-green-list,
        'key': $oc-yellow-list,
        'method': $oc-violet-list,
        'module': $oc-grape-list,
        'namespace': $oc-grape-list,
        'null': $oc-red-list,
        'number': $oc-violet-list,
        'object': $oc-orange-list,
        'operator': $oc-gray-list,
        'package': $oc-yellow-list,
        'property': $oc-gray-list,
        'string': $oc-orange-list,
        'struct': $oc-orange-list,
        'typeparameter': $oc-blue-list,
        'variable': $oc-blue-list,
    );

    // Default for unknown symbols
    color: $oc-gray-5;

    @each $kind, $color-list in $symbol-kinds {
        &--kind-#{$kind} {
            color: map-get($color-list, '5');
        }
    }
}
