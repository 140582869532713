@import '../../../views/ViewContent';
@import 'react-grid-layout/css/styles';
@import 'react-resizable/css/styles';

.insights-view-grid {
    .react-resizable-handle {
        background: none;
        cursor: nwse-resize;
        width: 1rem;
        height: 1rem;

        // Visual handle
        &::after {
            right: 0.25rem;
            bottom: 0.25rem;
            width: 0.5rem;
            height: 0.5rem;
            border-right: 2px solid var(--card-border-color);
            border-bottom: 2px solid var(--card-border-color);
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        padding: 0.5rem 1rem;
        cursor: default;
    }
}
