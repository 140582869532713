@import './ExtensionCard';
@import './ExtensionsList';
@import './ExtensionBanner.scss';

.extensions-area {
    width: 100%;
    &__content {
        height: 100%;
    }
    &__title {
        text-decoration: none !important;
        color: var(--body-color);
    }

    .card .filtered-connection__summary {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        color: var(--text-muted);
    }
    .navbar-expand {
        padding-bottom: 0;
        padding-top: 0.5rem;
    }
}
