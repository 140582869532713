@import './GitCommitNode';

.repository-commits-page {
    width: 100%;
    overflow: auto;
    &__content {
        margin: 0 auto;
        max-width: 54rem;
        padding: 1rem 0.5rem;
        display: flex;
        flex-direction: column;
    }
}
