.connection-popover {
    isolation: isolate;
    display: flex;
    flex-direction: column;

    min-width: 25rem;
    max-width: 70vw;

    --popover-item-padding-h: 0.75rem;
    --popover-item-padding-v: 0.325rem;

    .theme-redesign & {
        --popover-item-padding-h: 1rem;
        --popover-item-padding-v: 0.25rem;
        background-color: var(--color-bg-1);
    }

    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        min-height: 27rem; // avoid jitter when only loading indicator is shown
        max-height: 70vh;

        .theme-redesign & {
            min-height: initial;
            max-height: calc(min(20rem, 70vh));
        }

        .alert {
            word-break: break-word;
        }
    }

    &__nodes {
        .theme-redesign & {
            border-top: solid 1px var(--border-color-2);
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }
    }

    &__node {
        border-bottom: solid 1px var(--border-color);

        .theme-redesign & {
            border-bottom: none;
        }

        &:last-child {
            border-bottom: none;
        }
        &-link {
            display: flex;
            align-items: center;
            padding: var(--popover-item-padding-v) var(--popover-item-padding-h);
            color: var(--text-muted);

            .theme-redesign & {
                text-decoration: none;
                border: none;

                &:hover,
                &:focus {
                    background-color: var(--primary);
                    color: var(--light-text);
                }
            }

            &-icon {
                margin-left: 0.25rem;

                .theme-redesign & {
                    display: none;
                }
            }
            &--active {
                font-weight: bold;

                .theme-redesign & {
                    font-weight: inherit;
                    background-color: var(--color-bg-3);
                }
            }
        }
    }

    &__input {
        .theme-redesign & {
            margin: 0.5rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }

    &__show-more {
        flex: 0 0;
        border: none;
        border-radius: 0;
        text-align: left;
        font-size: 0.75rem;
        padding: var(--popover-item-padding-v) var(--popover-item-padding-h);
    }
}
