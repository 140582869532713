.site-admin-survey-responses-summary {
    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__item {
        width: 32%;
        @media (--md-breakpoint-up) {
            width: 100%;
            margin-bottom: 1rem;
        }
    }
}

.site-admin-survey-responses-connection {
    &__wide-border {
        border-width: 3px !important;
    }
}
