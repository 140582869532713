.icon-radio-buttons {
    padding: 0 3.3rem;
    display: flex;
    justify-content: space-around;

    &__label {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.25rem;
        width: 2.25rem;
        margin-bottom: 0;
        border-radius: 50%;
        transition: box-shadow 50ms ease-in;

        &:focus-within {
            box-shadow: inset 0 0 0 3px rgba($primary, 0.75);
            transition-delay: 0;
        }
    }

    &__border {
        z-index: 1;
        position: absolute;
        display: flex;
        height: 2.25rem;
        width: 2.25rem;
        cursor: pointer;
        margin: 0;
        border-radius: 50%;
        transform-origin: center center;
        transition: transform 0.15s ease, box-shadow 0.2s ease;
        border: 1px solid var(--border-color-2);

        &:hover {
            transform: scale(1.08);
            border-color: var(--border-color);
        }
        &:active {
            cursor: pointer;
            transform: scale(0.99);
        }
        &--active {
            border: none;
        }
    }
    &__emoji {
        transform-origin: center center;

        &--active {
            border: none;
            animation: pop 150ms;
            transform: scale(1.285555);
        }
        &--inactive {
            filter: grayscale(1);
            .theme-redesign & {
                border: none;
            }
        }
    }

    &__input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

@keyframes pop {
    from {
        transform: scale(0.95);
    }
    20% {
        transform: scale(1.6);
    }
    to {
        transform: scale(1.285555);
    }
}

.theme-redesign {
    .icon-radio-buttons__border--inactive {
        border: none;
    }
}
