.install-browser-extension-popover {
    isolation: isolate;
    width: 31rem;
    max-width: 100vw;
    top: -4px !important;

    &__graphic-container {
        margin-bottom: 2rem;
        width: 11.25rem;
    }

    &__plus-icon {
        color: $gray-10;
        height: 1.25rem;
        width: 1.25rem;
    }

    &__logo {
        height: 3.5rem;
        width: 3.5rem;
    }
}
