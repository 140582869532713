.activation-dropdown {
    width: 21rem;
    z-index: 999;
    padding-bottom: 0;
    overflow: hidden;

    &__checklist {
        .list-group-item {
            border: 0;
        }

        .activation-dropdown-header {
            border: 0;
        }
    }
}

.activation-dropdown-button {
    display: flex;
    border: none;
    color: var(--link-color);

    &__progress-bar-container {
        display: inline-block;
        width: 1rem;
        margin-top: -0.125rem;
        margin-left: 0.375rem;
    }
    &__circular-progress-bar {
        // stylelint-disable-next-line selector-class-pattern
        .CircularProgressbar-trail {
            stroke: $dropdown-border-color;
        }
        // stylelint-disable-next-line selector-class-pattern
        .CircularProgressbar-path {
            stroke: $oc-green-4;
        }
    }
    &__loader {
        padding-left: 0.8375rem;
    }

    &__confetti {
        z-index: 1000;
    }
}

.activation-dropdown-header {
    white-space: normal;
    border-bottom: 1px solid $dropdown-border-color;
    padding: 0.25rem 0.75rem 0 0.75rem;

    &:hover {
        text-decoration: none;
    }
}
