@import './SiteAdminUsageStatisticsPage';
@import './SiteAdminConfigurationPage';
@import './SiteAdminCreateUserPage';
@import './SiteAdminUpdatesPage';
@import './SiteAdminSurveyResponsesPage';
@import './SiteAdminMigrationsPage';

.site-admin-area .settings-file {
    flex: 1;
}
