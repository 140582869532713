.filter-chip {
    padding: 0 0.25rem;
    border-radius: 2px;
    margin: 0.25rem 0.5rem 0.25rem 0;

    color: var(--body-color);
    border-color: var(--border-color-2);
    background: var(--color-bg-2);

    &:hover:not(&--selected) {
        border-color: var(--border-color);
        background: var(--color-bg-3);
    }

    &__count {
        display: inline-block;
        margin-left: 0.25rem;
        padding: 0 0.2rem;

        background: var(--color-bg-2);
    }

    &-repo {
        padding-right: 0;
    }

    &--selected {
        border-color: var(--border-color);
        background: var(--color-bg-3);
    }
}
