@import '../QuickLinks';

.search-results-filter-bars {
    &__carousel {
        display: flex;

        // HACK: This will make the content grow to fix the space left.
        // Without a fixed width, the content will overflow the screen.
        width: 0;
        flex-grow: 1;
    }

    &__scroll {
        width: 1.5rem;
        padding: 0;
        margin: 0 0.125rem;

        &:hover {
            background-color: var(--link-hover-bg-color);
        }

        &--disabled {
            visibility: hidden;
            width: 0;
            margin: 0;
        }
    }

    &__row {
        display: flex;
        flex: 0 0 auto;
        padding-left: 1rem;
        align-items: center;

        color: var(--text-muted);
        background: var(--color-bg-1);
        border-bottom: 1px solid var(--border-color);
    }

    &__row-label--empty {
        color: var(--color-text-3);
    }

    &__filters {
        margin-left: 0.5rem;
        display: flex;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        &--no-label {
            margin-left: 0;
        }
    }

    &__quicklinks {
        padding-top: map-get($spacers, 1);
        padding-bottom: map-get($spacers, 1);
        display: flex;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
