.result-container {
    border: 0 solid var(--border-color);
    border-top-width: 1px;
    &:last-child {
        border-bottom-width: 1px;
    }

    .theme-redesign & {
        border: none;
        margin-right: 1rem;
    }

    &__header {
        padding: 0.5rem 0.5rem;

        display: flex;
        align-items: center;
        white-space: nowrap;
        $background-color: $card-cap-bg;

        background-color: $background-color;

        &-title {
            flex: 1 1 auto;
            margin: 0 0.25rem;
            overflow: hidden;
            display: flex;
        }

        &-divider {
            .theme-redesign & {
                border-right: 1px solid var(--border-color-2);
                height: 1rem;
                margin-left: 0.25rem;
            }
        }

        p {
            margin-bottom: 0;
        }

        &:not(:only-of-type) {
            border-bottom: 1px solid var(--border-color);
        }

        .theme-redesign & {
            background-color: transparent;
            padding: 0.5rem 0;

            &:not(:only-of-type) {
                border-bottom: none;
            }
        }
    }

    &__toggle-matches-container {
        display: flex;

        .theme-redesign & {
            flex-shrink: 0;
        }
    }
}
