@import '../components/ConnectionPopover';

.revisions-popover {
    isolation: isolate;
    width: 35rem;

    &__tabs {
        display: flex;
        justify-content: space-between;
        .theme-redesign & {
            padding-left: 1rem;
            padding-right: 1rem;
            margin-top: 0.25rem;
        }

        &-close {
            color: var(--icon-color);
        }
    }

    &-git-commit-node {
        &__link {
            display: flex;
        }
        &__message {
            flex: 1 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 0.5rem;

            .theme-redesign & {
                padding: 0 0.5rem 0;
            }
        }
        &__link:not(:hover) &__message {
            color: var(--body-color);

            .theme-redesign & {
                color: inherit;
            }
        }
        &__oid {
            margin-bottom: -0.125rem;

            .theme-redesign & {
                // Extend new default badge styles in redesign
                @extend .badge;
                // TODO: Update when we have specific badge font-sizes. Issue: https://github.com/sourcegraph/sourcegraph/issues/20675
                font-size: 0.75rem;
                margin-bottom: 0;
            }
        }
    }
}
