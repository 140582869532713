.tooltip {
    // Allows line breaks in tooltips
    white-space: pre-wrap;
    animation: 0.25s fade-in;
    pointer-events: none;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
