.loader-input {
    &__container {
        position: relative;
    }

    &__spinner {
        position: absolute;
        right: calc(0.5rem - 1px);
        top: calc(0.5rem - 1px);
    }
}
