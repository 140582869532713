.hero-page {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 0;
    padding-top: 8rem;
    text-align: center;
    align-items: center;
    overflow-y: auto;

    &__default-padding {
        padding-top: 8rem;
    }

    &__less-padding {
        padding-top: 4rem;
    }

    &__icon {
        border-radius: 100%;
        width: 6rem;
        height: 6rem;
        min-height: 6rem;
        background-color: var(--color-bg-2);
        display: flex;
        align-items: center;
        justify-content: center;

        .mdi-icon {
            fill: currentColor;
            width: 4rem;
            height: 4rem;
        }
    }

    &__title {
        font-size: 2rem;
        margin-top: 1rem;
        line-height: 1.5;
    }

    &__subtitle {
        margin-top: 1rem;
    }

    &__cta {
        margin-top: 1rem;
    }
}
