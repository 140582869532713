@import './ExtensionAreaHeader';
@import './RegistryExtensionOverviewPage';
@import './RegistryExtensionManifestPage';

.registry-extension-area {
    width: 100%;
    &__content {
        height: 100%;
    }

    .extensions-nav-link {
        // stylelint-disable-next-line declaration-property-unit-whitelist
        margin-top: 1px;
        &:hover {
            text-decoration: none !important;
        }
    }
}
