@import '~@reach/dialog/styles.css';

[data-reach-dialog-overlay] {
    background-color: rgba($gray-04, 0.5);
}
.theme-dark {
    [data-reach-dialog-overlay] {
        background-color: rgba($gray-19, 0.5);
    }
}

.modal-body {
    background-color: var(--body-bg);
    padding: 1rem;
    width: 32rem;
    max-width: 100vw;

    &--centered,
    &--top-third {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        margin: 0;
        left: 50%;
    }
    &--top-third {
        top: 33%;
    }
}
