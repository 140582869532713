.sourcegraph-notification-item {
    display: block;
    transition: all 300ms ease-in;
    padding: 0;

    &__progress {
        background: transparent;
        border-radius: 0;
    }

    &__progressbar {
        height: 0.25rem;
        transition: width 0.6s ease;
    }

    &__body-container {
        display: flex;
        align-items: flex-start;
    }
    &__body {
        flex: 1;
        padding: 0.5rem;
        overflow: hidden;
        pre,
        code {
            overflow-x: auto;
        }
    }

    &__content > :last-child,
    &__title > :last-child {
        margin-bottom: 0;
    }

    &__close {
        cursor: pointer;
        flex: 0 0;
        // stylelint-disable-next-line declaration-property-unit-whitelist
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border-width: 0;
        outline: 0 !important;
        color: inherit;
        opacity: 0.7;
        padding: 0.5rem;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}
