.repository-stats-page {
    &__card {
        margin-bottom: 1rem;
    }

    &__row {
        width: 100%;
    }

    &__btn {
        &-no-left-rounded-corners {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
