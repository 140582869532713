@import 'bootstrap/scss/alert';

.theme-light {
    @each $color, $value in map-merge($theme-colors, $theme-colors-light) {
        .alert-#{$color} {
            @include alert-variant(
                theme-color-level($color, $alert-bg-level-light),
                theme-color-level($color, $alert-border-level-light),
                theme-color-level($color, $alert-color-level-light)
            );
        }
    }
}

.alert-heading {
    color: inherit !important;
}

.alert-link {
    text-decoration: underline;
    color: inherit;
}

.alert a:not(.btn) {
    @extend .alert-link;
}

@import './alert-redesign';
