@import '../../../../branded/src/components/ToggleBig.scss';

.extension-area-header {
    &__wrapper {
        display: grid;
        grid-template-rows: auto auto;
    }

    &__actions {
        width: 16rem;
    }

    &__alert {
        position: absolute;
        bottom: $toggle-big-height + 0.25rem;
        left: $toggle-big-width / 2;
        transform: translateX(-50%);
        width: max-content;
        // hide alerts for narrow screens
        display: none;
    }

    @media (--md-breakpoint-up) {
        &__wrapper {
            display: grid;
            grid-template-columns: auto 7.5rem 7.5rem;
            grid-template-rows: none;
        }

        &__alert {
            display: block;
        }

        &__toggle-wrapper {
            justify-content: flex-end;
        }
    }
}
