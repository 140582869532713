.command-list {
    width: 24rem;
    background-color: var(--body-bg);

    &__popover-button {
        cursor: pointer;
    }
}

.theme-redesign {
    .command-list {
        .list-group {
            max-height: 18.5rem;
            overflow: auto;
        }
        .list-group-item {
            &.active {
                &:hover {
                    color: var(--body-color);
                }
            }
        }
        .list-group-item-action {
            &:hover {
                background-color: var(--color-bg-3);
            }
        }
    }
}
