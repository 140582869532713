.signin-signup-page {
    width: 100%;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.signin-signup-form {
    max-width: calc(100vw - 2rem);
    background-color: var(--color-bg-1);
    border: 1px solid var(--border-color-2);

    &__input-container {
        position: relative;
    }

    &__icon {
        position: absolute;
        right: calc(0.5rem - 1px);
        top: calc(0.5rem - 1px);
    }
}

.signin-page {
    &__container {
        width: 20rem;
    }
}

.signup-page {
    &__container {
        width: 27.5rem;
    }
}

.post-signup-page {
    margin-top: 7rem;
    &__container {
        width: 37.25rem;
    }
}
