@import './charts/line/LineChart';
@import './charts/pie/PieChart';
@import './charts/bar/BarChart';

.chart-view-content {
    height: 100%;
    width: 100%;
    position: relative;

    &__chart {
        // stretch chart over all parent block
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
