$toggle-big-width: 6.625rem;
$toggle-big-height: 2rem;

.toggle-big {
    background: none;
    border: none;
    display: inline-block;
    outline: none !important;
    padding: 0;
    position: relative;
    width: $toggle-big-width;

    &:disabled {
        cursor: not-allowed;
    }

    &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__bar,
    &__bar-shadow {
        border-radius: 1rem;
        height: $toggle-big-height;
        width: 100%;
        top: 2px;
        left: 0;
        position: absolute;
    }

    &__bar {
        opacity: 0.2;
        background-color: var(--text-muted);
        transition: all 0.3s;
        transition-property: opacity;

        &--on {
            background-color: var(--primary);
        }
    }

    &:hover:enabled &__bar {
        opacity: 0.5;
    }

    &:focus-visible &__bar {
        opacity: 0.5;
        background-color: var(--text-muted);

        &--on {
            background-color: var(--primary);
        }
    }

    &__bar-shadow {
        background-color: transparent;
    }

    &:focus-visible &__bar-shadow {
        opacity: 0.2;
        box-shadow: 0 0 0 0.1875rem var(--text-muted);

        &--on {
            box-shadow: 0 0 0 0.1875rem var(--primary);
        }
    }

    &__knob {
        background-color: var(--text-muted);
        border-radius: 0.75rem;
        height: 1.5rem;
        width: 1.5rem;
        margin-top: 0.375rem;
        left: 0.25rem;
        position: relative;

        &--on {
            background-color: var(--primary);
            transform: translate3d(4.625rem, 0, 0);
        }
    }

    &:disabled &__knob {
        opacity: 0.2;
    }

    &__icon {
        fill: $secondary-light;

        &--on {
            fill: $white;
        }
    }

    &__text {
        color: var(--body-color);
        position: relative;
        margin-top: 0.375rem;
        right: -0.6875rem;

        &--on {
            right: 0.4375rem;
        }
    }

    &:disabled &__text {
        opacity: 0.2;
    }
}
