.empty-command-list {
    position: relative;
    color: $gray-17;
    padding: 1rem;

    &__title {
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    &__text {
        line-height: 1.5;
        margin-bottom: 2rem;
    }

    &__illustration {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
    .theme-redesign & {
        background-color: var(--color-bg-1);
    }
}

.theme-dark {
    .empty-command-list {
        color: $gray-06;
    }
}
