/* stylelint-disable selector-class-pattern */
.payment-token-form-control {
    &__card {
        @extend .form-control;
        padding-top: 0.5rem;

        &.StripeElement--focus {
            @extend .form-control, :focus;
        }
        &.StripeElement--webkit-autofill {
            background: transparent !important;
        }
    }
}

// The default disabled colors on the light theme look disabled, but on the dark theme they are too vivid, so make
// them less so.
.theme-dark {
    .payment-token-form-control {
        &__card {
            &--disabled {
                opacity: 0.65;
            }
        }
    }
}
