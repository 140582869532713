@import './VersionContextDropdown';

.global-navbar {
    flex: 0 0 auto;
    max-height: 80%;
    display: flex;
    align-items: center;
    width: 100%;
    &--bg {
        background-color: var(--color-bg-2);
    }

    &__logo-link {
        outline: 0;
        margin: 0.25rem 0.5rem;
        display: flex;
        width: fit-content;
        text-decoration: none;
    }

    &__logo {
        height: 1.5rem;
        width: 1.5rem;
    }

    &__search-box-container {
        min-width: 12rem;
        flex: 1 1 auto;
        margin-right: 0.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .theme-redesign & {
        &__icon {
            color: var(--header-icon-color);
        }

        &__link {
            color: var(--body-color);
            border-radius: var(--border-radius);
        }

        &__sign-up {
            border: 1px solid var(--brand-secondary);
        }
    }
}
