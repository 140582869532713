.dismissible-alert {
    display: flex;
    align-items: flex-start;
    padding-right: 0.5rem;

    &__content {
        flex: 1 1 auto;
        display: flex;
    }
}
