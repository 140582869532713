.codeintel-index-node {
    &__separator {
        // Make it full width in the current row.
        grid-column: 1 / -1;
        border-top: 1px solid var(--border-color-2);
        @media (--xs-breakpoint-down) {
            margin-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    &__state,
    &__information {
        @media (--xs-breakpoint-down) {
            grid-column: 1 / -1;
        }
    }
}
