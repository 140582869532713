.resizable {
    flex: 0 0 auto;
    display: flex;

    &--top {
        flex-direction: column-reverse;
    }
    &--left {
        flex-direction: row-reverse;
    }

    $handle-size: 0.35rem;

    &__handle {
        position: relative;

        opacity: 0;
        user-select: none;
        z-index: 1;

        &--right,
        &--left {
            height: 100%;
            width: $handle-size;
            cursor: ew-resize;
            top: 0;
            bottom: 0;
        }
        &--left {
            margin-left: calc(-1 * #{$handle-size});
            right: 0;
        }
        &--right {
            margin-right: calc(-1 * #{$handle-size});
            left: 0;
        }
        &--top {
            width: 100%;
            height: $handle-size;
            margin-bottom: calc(-1 * #{$handle-size});
            cursor: ns-resize;
            top: 0;
            left: 0;
            right: 0;
        }
    }
}
