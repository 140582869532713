.layout {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;

    // Prevent any internal z-index (heavily used by Bootstrap) from interfering
    // with global React Portal UI such a Reach modals or Shepherd search onboarding tour.
    isolation: isolate;

    &__app-router-container {
        flex: 1 1 auto;
        min-height: 0;
        display: flex;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        width: 100%;
        // We set height explicitly to be able to have position sticky elements
        // within the app-router-container element. Without height property on root
        // with overflow value different from 'visible' value sticky elements
        // do not work properly.
        // See https://github.com/w3c/csswg-drafts/issues/865#issuecomment-629076458
        height: 100%;
    }
}
