@mixin theme-light-rules() {
    @at-root #{selector-append('.theme-light', &)} {
        @content;
    }
}

@mixin theme-dark-rules() {
    @at-root #{selector-append('.theme-dark', &)} {
        @content;
    }
}
