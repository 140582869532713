.diff-stat {
    display: inline-flex;
    align-items: center;

    &__total {
        padding-right: 0.125rem;
        // stylelint-disable-next-line declaration-property-unit-whitelist
        margin-bottom: -1px;
    }

    &__square {
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        margin-left: 0.125rem;
    }

    &__empty {
        background-color: var(--text-muted);
    }
}
