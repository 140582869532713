@import './UserNavItem.scss';
@import './StatusMessagesNavItem.scss';
@import './Feedback/FeedbackPrompt.scss';

.nav-links {
    position: relative; // For help form
    white-space: nowrap;
    flex-wrap: nowrap;

    // Less padding above, below, and between links.
    .nav-item .nav-link {
        padding-left: 0.375rem;
        padding-right: 0.375rem;
        user-select: none;

        &:hover {
            border-color: transparent;
        }
    }

    // Hide icon-placeholders by default.
    .nav-link--with-icon-placeholder .icon-inline {
        display: none;
    }

    // Show icon-placeholders only for nav-links inside of the dropdown-menu to keep them aligned.
    .nav-item--dropdown .nav-link--with-icon-placeholder .icon-inline {
        display: inline-block;
    }
}
